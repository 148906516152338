<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Devices : {{ siteInfo.site_name_en || null }}
          <v-spacer></v-spacer>
          <span class="subtitle-2">
            <v-dialog
              v-model="isDialogVisible"
              persistent
              max-width="600px"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  small
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disableAdd"
                  @click="newItem"
                >
                  Add
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline" v-if="deviceInfo.new !== false">New Device</span>
                  <span class="headline" v-else>Edit Device</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-alert
                    v-if="saveDeviceError !== null"
                    type="error"
                    dense
                    dismissible
                  >
                    {{ saveDeviceError }}
                  </v-alert>
                  <v-form ref="formDevice">
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                        >
                          <v-select
                            v-model="deviceInfo.device_id"
                            :items="deviceList.nixt"
                            item-value="_id"
                            item-text="deviceName"
                            :loading="isLoadingNixtDevice"
                            label="Device (NiXT)"
                            dense
                            outlined
                            hide-details
                            :rules="[required]"
                            :disabled="!deviceInfo.new"
                            @change="selectNewDevice"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="deviceInfo.device_name_en"
                            label="Device Type"
                            dense
                            outlined
                            hide-details
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-select
                            v-model="deviceInfo.brand"
                            :items="deviceBrand"
                            item-value="v"
                            item-text="label"
                            label="Brand"
                            dense
                            outlined
                            hide-details
                          ></v-select>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="deviceInfo.model"
                            label="Model"
                            dense
                            outlined
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-divider class="my-3"></v-divider>
                      <v-row v-if="deviceInfo.device_type === 'INV'">
                        <v-col cols="3" v-for="index in 8" :key="index">
                          <v-text-field
                            v-model.number="deviceInfo[`pv${index}_no`]"
                            :label='`PV${index} No`'
                            dense
                            outlined
                            hide-details
                            type="number"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    color="error"
                    outlined
                    :disabled="savingDevice"
                    @click="closeDialog"
                  >
                    Close
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    :loading="savingDevice"
                    @click="saveData"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </span>
        </v-card-title>
        <div>
          <v-data-table
            :headers="headers"
            :items="deviceList.list"
            :items-per-page="5"
            :loading="tableLoading"
          >
            <template #[`item.actions`]="{ item }">
              <v-icon
                class="me-4"
                color="error"
                small
                @click="editItem(item)"
              >
                {{ icons.mdiSquareEditOutline }}
              </v-icon>
              <v-icon
                color="error"
                small
                @click="deleteItem(item)"
              >
                {{ icons.mdiDelete }}
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <confirm ref="veeConfirmRef"></confirm>
    </v-col>
  </v-row>
</template>
<script>

import { ref, reactive, onMounted, computed } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { mdiDelete, mdiSquareEditOutline, mdiFormatListBulleted } from '@mdi/js'
import store from '@/store'
import axios from '@axios'

/* eslint-disable no-underscore-dangle */
export default {
  components: {
    Confirm: () => import('@/components/Confirm.vue')
  },
  props: {
    siteId: {
      required: true,
    },
  },
  setup(props) {
    const deviceList = reactive({
      list: [],
      nixt: [],
    })
    const tableLoading = ref(true)
    const veeConfirmRef = ref(null)
    const isDialogVisible = ref(false)
    const siteInfo = computed(() => store.state.sites.list.find(item => item.site_id === props.siteId) || {})
    const datePicker = ref(false)
    const disableAdd = computed(() => deviceList.nixt.filter(nixt => !deviceList.list.map(item => item.device_id).includes(nixt._id)).length <= 0)

    const deviceBrand = [
      { v: 'huawei', label: 'Huawei' },
      { v: 'alpha', label: 'Alpha' },
    ]
    const deviceInfo = reactive({
      new: false,
      site_id: null,
      device_id: null,
      device_type: null,
      brand: null,
      model: null,
      device_name_nixt: null,
      device_name_en: null,
      pv1_no: 0,
      pv2_no: 0,
      pv3_no: 0,
      pv4_no: 0,
      pv5_no: 0,
      pv6_no: 0,
      pv7_no: 0,
      pv8_no: 0,
      rate_power: null,
    })

    const isLoadingNixtDevice = ref(false)
    const loadNixtDevice = () => {
      isLoadingNixtDevice.value = true
      store.dispatch('devices/fetchNixtDevices', { siteId: props.siteId })
        .then(response => {
          if (typeof response.data.data.devices !== 'undefined') {
            deviceList.nixt = response.data.data.devices
          } else {
            deviceList.nixt = []
          }
        })
        .finally(() => { isLoadingNixtDevice.value = false })
    }
    const loadDeviceList = () => {
      tableLoading.value = true
      store.dispatch('devices/fetchDevices', { siteId: props.siteId })
        .then(() => {
          deviceList.list = store.state.devices.list
          tableLoading.value = false
          loadNixtDevice()
        })
    }

    const deviceNameEn = {
      INV: 'INVERTER',
      GME: 'GRID_METER',
      GEN: 'GENERATOR',
      EMI: 'WEATHER_STATION',
      BAT: 'BATTERY',
      LME: 'LOAD_METER',
      BME: 'BILLING_METER',
      SML: 'SMART_LOGGER',
    }

    const selectNewDevice = deviceId => {
      const nixtInfo = deviceList.nixt.find(item => item._id === deviceId)
      deviceInfo.site_id = nixtInfo.siteId
      deviceInfo.device_id = nixtInfo._id
      deviceInfo.device_type = nixtInfo.deviceType
      deviceInfo.device_name_nixt = nixtInfo.deviceName
      deviceInfo.device_name_en = deviceNameEn[nixtInfo.deviceType] || null
    }

    const formDevice = ref(null)
    const saveDeviceError = ref(null)

    const savingDevice = ref(false)
    const saveData = () => {
      saveDeviceError.value = null
      if (formDevice.value.validate()) {
        savingDevice.value = true
        axios
          .post(deviceInfo.new ? '/device/deviceadd' : '/device/deviceupdate', {
            site_id: deviceInfo.site_id,
            device_id: deviceInfo.device_id,
            device_type: deviceInfo.device_type,
            brand: deviceInfo.brand,
            model: deviceInfo.model,
            device_name_nixt: deviceInfo.device_name_nixt,
            device_name_en: deviceInfo.device_name_en,
            pv1_no: deviceInfo.pv1_no,
            pv2_no: deviceInfo.pv2_no,
            pv3_no: deviceInfo.pv3_no,
            pv4_no: deviceInfo.pv4_no,
            pv5_no: deviceInfo.pv5_no,
            pv6_no: deviceInfo.pv6_no,
            pv7_no: deviceInfo.pv7_no,
            pv8_no: deviceInfo.pv8_no,
            rate_power: deviceInfo.rate_power,
          })
          .then(response => {
            const { code, message } = response.data
            if (code !== '0') {
              saveDeviceError.value = message
            } else {
              loadDeviceList()
              formDevice.value.reset()
              isDialogVisible.value = false
            }
          })
          .catch(() => {
            saveDeviceError.value = 'Error while processing'
          })
          .finally(() => { savingDevice.value = false })
      }
    }
    const closeDialog = () => {
      if (!savingDevice.value) {
        isDialogVisible.value = false
        formDevice.value.reset()

        return true
      }

      return false
    }

    const newItem = () => {
      deviceInfo.new = true
      loadNixtDevice()
    }

    const editItem = device => {
      isDialogVisible.value = true
      deviceInfo.new = false
      deviceInfo.site_id = device.site_id
      deviceInfo.device_id = device.device_id
      deviceInfo.device_type = device.device_type
      deviceInfo.brand = device.brand
      deviceInfo.model = device.model
      deviceInfo.device_name_nixt = device.device_name_nixt
      deviceInfo.device_name_en = device.device_name_en
      deviceInfo.pv1_no = device.pv1_no
      deviceInfo.pv2_no = device.pv2_no
      deviceInfo.pv3_no = device.pv3_no
      deviceInfo.pv4_no = device.pv4_no
      deviceInfo.pv5_no = device.pv5_no
      deviceInfo.pv6_no = device.pv6_no
      deviceInfo.pv7_no = device.pv7_no
      deviceInfo.pv8_no = device.pv8_no
      deviceInfo.rate_power = device.rate_power
    }

    const deleteItem = item => {
      veeConfirmRef.value
        .open('Deleting Site', `Are you sure to delete '${item.device_name_nixt}' ?`, { color: 'error' })
        .then(confirm => {
          if (confirm === true) {
            axios
              .post('/device/deviceremove', {
                device_id: `${item.device_id}`,
              })
              .then(response => {
                const { code, message } = response.data
                if (code === '0') {
                  loadDeviceList()
                } else {
                  // Todo: display error
                }
              })
          }
        })
    }

    onMounted(() => {
      store.dispatch('sites/fetchSites')
      loadDeviceList()
    })

    return {
      headers: [
        { text: 'NAME', value: 'device_name_nixt' },
        { text: 'TYPE', value: 'device_type', align: 'center' },
        {
          text: 'BRAND', value: 'brand' , align: 'center', cellClass: 'text-capitalize'
        },
        { text: 'MODEL', value: 'model' },
        { text: 'UPDATE DATE', value: 'update_date' },
        {
          text: 'ACTIONS', value: 'actions', align: 'center', sortable: false,
        },
      ],
      deviceBrand,
      deviceList,
      deviceInfo,
      tableLoading,
      veeConfirmRef,
      isDialogVisible,
      isLoadingNixtDevice,
      siteInfo,
      savingDevice,
      datePicker,
      formDevice,
      saveDeviceError,
      disableAdd,

      loadNixtDevice,
      selectNewDevice,
      saveData,
      closeDialog,
      newItem,
      editItem,
      deleteItem,

      icons: {
        mdiDelete,
        mdiSquareEditOutline,
        mdiFormatListBulleted,
      },
      required,
    }
  },
}
</script>
